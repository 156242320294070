import React, { useState } from "react";
import Button from "../common/Button";
import Input from "../common/form/Input";
import DatePicker from "../common/form/DatePicker";
import Select from "../common/form/Select";
import { MinusCircleIcon } from "@heroicons/react/24/solid";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import Toggle from "../common/Toggle";

const ApplicationItem = ({ index, handleDeleteRow, config, formik, error }) => {
  const [expand, setExpand] = useState(true);

  return (
    <div className="mb-4">
      <div
        className={`flex items-center justify-between px-4 py-2 cursor-pointer bg-gray-f2 rounded-md ${
          error?.inValidIndex && error.inValidIndex.indexOf(index + 1) !== -1
            ? "border rounded-md border-red-600"
            : ""
        }`}
        onClick={() => setExpand(!expand)}
      >
        <span>Nitrogen Application {index + 1}</span>
        <div className="flex items-center gap-x-3">
          {index > 0 && (
            <MinusCircleIcon
              className="w-5 h-5 cursor-pointer text-red-600"
              onClick={() => handleDeleteRow(index)}
            />
          )}
          {expand ? (
            <ChevronDownIcon className="w-5 h-5 inline-block" />
          ) : (
            <ChevronUpIcon className="w-5 h-5 inline-block" />
          )}
        </div>
      </div>
      {expand && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 p-4">
          {config.fields.map((field) => {
            const { key, date, dropdown, options, label, bool } = field;
            const formKey = `${config.prefix}-${index + 1}-${key}`;
            if (date) {
              return (
                <div key={formKey} className="col-span-1">
                  <DatePicker
                    name={formKey}
                    inputClassName="h-[38px]"
                    value={formik.values[formKey]}
                    label={label}
                    onChange={(e) =>
                      formik.setFieldValue(formKey, e.target.value)
                    }
                  />
                </div>
              );
            } else if (dropdown) {
              return (
                <div key={formKey} className="col-span-1">
                  <Select
                    extraOptions={options}
                    onChange={(option) => {
                      formik.setFieldValue(formKey, option);
                    }}
                    label={label}
                    value={formik.values[formKey]}
                    className="w-full"
                  />
                  {formik.errors[formKey] && (
                    <div className="text-xs text-red-600 mt-1 w-full">
                      {formik.errors[formKey]}
                    </div>
                  )}
                </div>
              );
            } else if (bool) {
              return (
                <div
                  key={formKey}
                  className="col-span-1 flex items-center justify-center"
                >
                  <Toggle
                    key={key}
                    value={formik.values[key]}
                    label={label}
                    onChange={(value) => formik.setFieldValue(key, value)}
                  />
                </div>
              );
            } else {
              return (
                <div key={formKey} className="col-span-1">
                  <Input
                    name={formKey}
                    inputClassName="text-center px-0 h-[38px]"
                    value={formik.values[formKey]}
                    label={label}
                    onChange={(e) => {
                      formik.setFieldValue(formKey, e.target.value);
                      formik.setFieldTouched(formKey);
                    }}
                  />
                </div>
              );
            }
          })}
        </div>
      )}
    </div>
  );
};

const CropNitrateApplicationNew = (props) => {
  const { formik, config, numberApp, setNumberApp } = props;
  const handleDeleteRow = (index) => {
    for (let i = index; i < numberApp; i++) {
      config.fields.forEach(({ key }) => {
        const formKey = `${config.prefix}-${i + 1}-${key}`;
        if (formik.values[formKey]) {
          if (i !== index) {
            formik.setFieldValue(
              `${config.prefix}-${i}-${key}`,
              formik.values[formKey]
            );
          }
          formik.setFieldValue(formKey, undefined);
        }
      });
    }
    setNumberApp(numberApp - 1);
  };

  return (
    <div>
      <div className="overflow-y-auto nitrateAppForm">
        {Array(numberApp)
          .fill("")
          .map((item, index) => (
            <ApplicationItem
              key={`nitrate_app_${index}`}
              index={index}
              handleDeleteRow={handleDeleteRow}
              {...props}
            />
          ))}
      </div>
      <div className="flex justify-end mt-3">
        <Button
          onClick={(e) => {
            e.preventDefault();
            setNumberApp(numberApp + 1);
          }}
        >
          {config.prefix === "nitrateApp"
            ? "Add Application Event"
            : "Add Application"}
        </Button>
      </div>
    </div>
  );
};

export default CropNitrateApplicationNew;
