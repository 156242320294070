export const applicationConfig = {
  prefix: "nitrateApp",
  fields: [
    {
      key: "date_applied",
      label: "Date",
      date: true,
      colSpan: "w-[23%]",
    },
    {
      key: "emergent_type",
      label: "Emergence Type",
      dropdown: true,
      options: ["Pre-emergence", "Post-emergence"],
      colSpan: "w-1/4",
    },
    {
      key: "type",
      label: "Type",
      colSpan: "w-1/6",
    },
    {
      key: "amount",
      label: "Amount",
      number: true,
      colSpan: "w-1/6",
    },
    {
      key: "units",
      label: "Units",
      dropdown: true,
      options: ["Lbs/Ac", "Gallons/Ac", "Oz/Ac", "Liters/Ac"],
      colSpan: "w-1/6",
    },
    {
      key: "inhibitor_used",
      label: "Inhibitor Used",
      bool: true,
      colSpan: "w-1/6",
    },
    {
      key: "inhibitor_type",
      label: "Inhibitor Type",
      dropdown: true,
      options: [
        "Nitrification Inhibitor",
        "Urease Inhibitor",
        "Nitrification & Urease Inhibitor",
        "Biologic",
      ],
      colSpan: "w-1/6",
    },
  ],
};

export const inhibitorConfig = {
  prefix: "nitrateInhibitor",
  fields: [
    {
      key: "date_applied",
      label: "Date",
      date: true,
      colSpan: "w-[20%]",
    },
    {
      key: "type",
      label: "Type",
      dropdown: true,
      options: ["Biologic", "Chemical"],
      colSpan: "w-1/6",
    },
    {
      key: "name",
      label: "Name",
      colSpan: "w-1/6",
    },
    {
      key: "amount",
      label: "Amount",
      number: true,
      colSpan: "w-[10%]",
    },
    {
      key: "units",
      label: "Units",
      dropdown: true,
      options: ["Lbs/Ac", "Gallons/Ac", "Oz/Ac", "Liters/Ac"],
      colSpan: "w-1/6",
    },
    {
      key: "acres_treated",
      label: "Acres Treated",
      number: true,
      colSpan: "w-1/6",
    },
  ],
};

export const steps = [
  {
    name: "Year and Crop",
    description: "Select Year of Report and Crop Type",
    title: "Crop Report Year and Crop Type",
    type: "form",
    fields: [
      {
        key: "Crop_Planted",
        dropdown: true,
        options: [
          "Alfalfa",
          "Corn",
          "Dry Beans",
          "Fallow",
          "Field Beans",
          "Grain Sorghum",
          "Grass",
          "Millet",
          "Oats",
          "Other",
          "Potatoes",
          "Small Grains",
          "Sorghum / Sudan Grass",
          "Sorghum Sudan Grass",
          "Soybeans",
          "Sugar Beets",
          "Sunflowers",
          "Wheat",
        ],
      },
      {
        key: "Year",
        label: "Crop Report Year",
        number: true,
        comment: "Year that the crop was harvested",
      },
      {
        key: "Crop_Yield",
        number: true,
        comment: "Yield of the crop at harvest",
      },
      {
        key: "Crop_Yield_Units",
        dropdown: true,
        options: ["bushels / ac", "pounds / ac", "tons / ac"],
      },
      {
        key: "Corn_Yield_Goal",
        label: "Corn Yield Goal (bushels / ac)",
        number: true,
      },
    ],
    validation: ({ errors, values }) => {
      if (!values.Crop_Planted) {
        return "Crop Planted is required field";
      }
      if (!errors.Year && !errors.Crop_Yield && !errors.Corn_Yield_Goal) {
        return true;
      }
      return "";
    },
  },
  {
    name: "Irrigation Type and Acres",
    description: "Number of Acres for Each Irrigation Type",
    title: "Irrigation Type and Acres - $$crop",
    className: "md:grid-cols-9",
    type: "form",
    fields: [
      {
        key: "Sprinkler_Acres",
        number: true,
        comment: "Number of acres of this crop irrigated by a sprinkler",
        elementClassName: "md:col-span-4",
        hasPercent: true,
      },
      {
        key: "Percent_Sprinkler_Acres",
        label: "Percent of Tract Sprinkler Irrigated for Crop",
        dropdown: true,
        percent: true,
        options: [0, 10, 25, 33, 50, 67, 75, 90, 100],
        elementClassName: "md:col-span-4",
      },
      {
        key: "Flood_Acres",
        number: true,
        comment: "Number of acres of this crop irrigated by a flood",
        elementClassName: "md:col-span-4",
        hasPercent: true,
      },
      {
        key: "Percent_Flood_Acres",
        label: "Percent of Tract Flood Irrigated for Crop",
        dropdown: true,
        percent: true,
        options: [0, 10, 25, 33, 50, 67, 75, 90, 100],
        elementClassName: "md:col-span-4",
      },
      {
        key: "Other_Irr_Acres",
        label: "Other Acres",
        number: true,
        comment:
          "Number of acres of this crop irrigated by a any other method including dryland",
        elementClassName: "md:col-span-4",
        hasPercent: true,
      },
      {
        key: "Percent_Other_Irr_Acres",
        label: "Percent of Tract Other Irrigated for Crop",
        dropdown: true,
        percent: true,
        options: [0, 10, 25, 33, 50, 67, 75, 90, 100],
        elementClassName: "md:col-span-4",
      },
    ],
    validation: ({ values }, total) => {
      const acres =
        (parseFloat(values.Sprinkler_Acres) || 0) +
        (parseFloat(values.Flood_Acres) || 0) +
        (parseFloat(values.Other_Irr_Acres) || 0) +
        ((parseFloat(values.Percent_Sprinkler_Acres) || 0) * total) / 100 +
        ((parseFloat(values.Percent_Flood_Acres) || 0) * total) / 100 +
        ((parseFloat(values.Percent_Other_Irr_Acres) || 0) * total) / 100;

      if (0 < acres && acres <= total) {
        return true;
      }
      return `Total acres must be greater than 0 and smaller than total tract acres: ${total}`;
    },
  },
  {
    name: "Irrigation Application and Tillage",
    description: "Irrigation Water Applied, Water Nitrate, Tillage",
    title:
      "Irrigation Water Application & Nitrate, Tillage Practice - $$totalAcres Acres of $$crop",
    className: "md:grid-cols-4 gap-5 mb-20",
    type: "form",
    fields: [
      {
        key: "Irrigation_Water_Applied",
        suffix: "inches",
        number: true,
        elementClassName: "md:col-span-2",
      },
      {
        key: "Water_Nitrate",
        suffix: "ppm NO-3",
        number: true,
        elementClassName: "md:col-span-2",
      },
      {
        key: "Tillage_Practice",
        dropdown: true,
        options: ["No Till", "Reduced Till", "Strip Till", "Conventional Till"],
        elementClassName: "md:col-start-2 md:col-end-4",
      },
    ],
  },
  {
    name: "Nitrogen Applied",
    description: "Nitrogen Application Amounts and Types",
    title: "Nitrogen Applied - $$totalAcres Acres of $$crop",
    // className: "md:grid-cols-4 gap-5 mt-14",
    className: "md:grid-cols-4 gap-5",
    type: "nitrate_application",
    fields: [
      {
        key: "Yield_Goal",
        label: "Corn Yield Goal",
        suffix: "bu/ac",
        number: true,
        elementClassName: "md:col-start-2 md:col-end-4",
      },
    ],
    validation: ({ values, setFieldError, errors }, tractAcres, numberApp) => {
      let isValid = true;
      const inValidIndex = [];
      for (let i = 1; i <= numberApp; i++) {
        let isAppValid = true;
        if (!values[`${applicationConfig.prefix}-${i}-emergent_type`]) {
          isAppValid = false;
          isValid = "Please enter all required fields";
          setFieldError(
            `${applicationConfig.prefix}-${i}-emergent_type`,
            "Field is required"
          );
        }
        if (
          values[`${applicationConfig.prefix}-${i}-amount`] &&
          !values[`${applicationConfig.prefix}-${i}-units`]
        ) {
          isAppValid = false;
          isValid = "Please enter all required fields";
          setFieldError(
            `${applicationConfig.prefix}-${i}-units`,
            "Field is required"
          );
        }
        if (!values[`${applicationConfig.prefix}-${i}-date_applied`]) {
          isAppValid = false;
          isValid = "Please enter all required fields";
          setFieldError(
            `${applicationConfig.prefix}-${i}-date_applied`,
            "Field is required"
          );
        }
        if (!isAppValid) {
          inValidIndex.push(i);
        }
      }
      return isValid === true
        ? isValid
        : {
            isValid,
            inValidIndex,
          };
    },
  },
  {
    name: "Manure Application",
    description: "Manure Application",
    title: "Manure Application - $$totalAcres Acres of $$crop",
    type: "form",
    fields: [
      {
        key: "manureApplications-type",
        label: "Manure",
        dropdown: true,
        options: [
          "None",
          "Beef - Open Lot - Solid Manure",
          "Beef - Confinement Barn & Storage - Slurry",
          "Beef - Holding Pond - Liquid",
          "Beef - Holding Pond - Solids",
          "Beef - Open Lot -Compost",
          "Dairy - Confinement Barn - Solid Manure",
          "Dairy - Confinement Barn & Storage - Slurry",
          "Dairy - Confinement Barn & Lagoon - Liquid",
          "Dairy - Confinement Barn & Lagoon - Sludge",
          "Swine - Confinement Barn & Deep Pit - Slurry",
          "Swine - Confinement Barn & Lagoon - Liquid",
          "Swine - Confinement Barn & Lagoon - Sludge",
          "Layers - Confinement Barn - Scrapped",
          "Layers - Confinement Barn & Storage - Slurry",
          "Broilers - Confinement Barn - Litter",
          "Turkey - Confinement Barn - Litter",
        ],
        elementClassName: "md:col-span-2",
      },
      {
        key: "manureApplications-ammonium_amount",
        label: "Ammonium Nitrogen Amount",
        suffix: "lbs/ton",
        number: true,
      },
      {
        key: "manureApplications-organic_amount",
        label: "Organic Nitrogen Amount",
        suffix: "lbs/ton",
        number: true,
      },
      {
        key: "manureApplications-years_ago_applied",
        label: "Year Applied",
        dropdown: true,
        options: [
          {
            label: "Current Year",
            value: 0,
          },
          {
            label: "1 Year Ago",
            value: 1,
          },
          {
            label: "2 Years Ago",
            value: 2,
          },
          {
            label: "3 Years Ago",
            value: 3,
          },
        ],
      },
      {
        key: "manureApplications-method",
        label: "Application Method",
        dropdown: true,
        options: [
          "Applied in summer or fall, injected or incorporated immediately",
          "Applied in summer or fall, incorporated 1 day later",
          "Applied in summer or fall, incorporated 2 days later",
          "Applied in summer or fall, incorporated 3-7 days later",
          "Applied in summer or fall, not incorporated within one week",
          "Applied in winter or spring, injected or incorporated immediately",
          "Applied in winter or spring, incorporated 1 day later",
          "Applied in winter or spring, incorporated 2 days later",
          "Applied in winter or spring, incorporated 3-7 days later",
          "Applied in winter or spring, not incorporated within one week",
          "Sidedress, injection",
          "Irrigation, sprinkler",
          "Irrigation, flood",
        ],
      },
      {
        key: "manureApplications-amount",
        label: "Amount",
        suffix: "Tons/acre",
        number: true,
      },
      {
        key: "manureApplications-date_applied",
        label: "Date Applied",
        date: true,
      },
    ],
  },
  // {
  //   name: "Nitrogen Inhibitor",
  //   description: "Any Inhibitor Used & Notes About Crop Report",
  //   title: "Nitrogen Inhibitor - $$totalAcres Acres of $$crop",
  //   className: "md:grid-cols-8 gap-3 mt-14",
  //   type: "nitrate_inhibitor",
  //   fields: [
  //     {
  //       key: "Is_Used",
  //       label: "Inhibitor Used",
  //       bool: true,
  //       elementClassName: "md:col-span-2 flex items-center",
  //     },
  //     {
  //       key: "Inhibitor_Name",
  //       label: "Name of Nitrogen Inhibitor",
  //       elementClassName: "md:col-span-3",
  //     },
  //     {
  //       key: "Acres_Treated",
  //       label: "Acres Treated",
  //       number: true,
  //       comment: "Number of acres treated with the Inhibitor",
  //       elementClassName: "md:col-span-3",
  //     },
  //     {
  //       key: "Note",
  //       label: "Notes About Entire Crop Report",
  //       textarea: true,
  //       elementClassName: "md:col-span-8",
  //     },
  //   ],
  //   validation: (
  //     { values, setFieldError },
  //     tractAcres,
  //     numberApp,
  //     numberInhibitor
  //   ) => {
  //     let isValid = true;
  //     for (let i = 1; i <= numberInhibitor; i++) {
  //       if (
  //         values[`${inhibitorConfig.prefix}-${i}-amount`] &&
  //         !values[`${inhibitorConfig.prefix}-${i}-units`]
  //       ) {
  //         isValid = "Please enter all required fields";
  //         setFieldError(
  //           `${inhibitorConfig.prefix}-${i}-units`,
  //           "Field is required"
  //         );
  //       }
  //       if (
  //         values[`${inhibitorConfig.prefix}-${i}-amount`] &&
  //         !values[`${inhibitorConfig.prefix}-${i}-date_applied`]
  //       ) {
  //         isValid = "Please enter all required fields";
  //         setFieldError(
  //           `${inhibitorConfig.prefix}-${i}-date_applied`,
  //           "Field is required"
  //         );
  //       }
  //     }
  //     return isValid;
  //   },
  // },
  {
    name: "Summary",
    description: "Data Entered and Upload Result",
    title: "Confirm Crop Report - $$totalAcres Acres of $$crop",
    type: "submit",
  },
];
